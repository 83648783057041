import AppRouter from './routing';

import './App.scss';
import "./global/globals.scss";
import "./global/helpers.scss";

function App() {
  return <AppRouter />;
}

export default App;
