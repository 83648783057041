import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { 
    ACCOUNTS_SCREEN, 
    ADMIN_SCREEN, 
    AGENTS_SCREEN, 
    ANALYSIS_SCREEN, 
    DEFINE_PWD_SCREEN, 
    HOME_SCREEN, 
    MERCHANTS_SCREEN, 
    SIGNUP_SCREEN, 
    SPONSORSHIP_SCREEN, 
    TRANSACTIONS_SCREEN, 
    VERIFY_EMAIL_SCREEN, 
    WALLET_SCREEN 
} from "./settings/constant"
import { Suspense, lazy } from "react"
import Layout from "./layout/Layout";
import PrivateRoute from "./PrivateRoute";

const SignUpScreen = lazy(() => import("./pages/bo/authentification/SigninScreen"));
const ResetPwdScreen = lazy(() => import("./pages/bo/authentification/DefinePwdScreen"));
const VefifyEmailScreen = lazy(() => import("./pages/bo/authentification/VefifyEmailScreen"));
const HomeScreen = lazy(() => import("./pages/bo/home"));
const TransactionsScreen = lazy(() => import("./pages/bo/transactions/Transactions"));
const MerchantsScreen = lazy(() => import("./pages/bo/merchants/Merchants"));
const AccountsScreen = lazy(() => import("./pages/bo/account/Accounts"));
const AnalysisScreen = lazy(() => import("./pages/bo/analysis/Analysis"));
const SponsorScreen = lazy(() => import("./pages/bo/sponsorShip/SponsorShip"));
const AdminScreen = lazy(() => import("./pages/bo/admin/Admin"));
const WalletScreen = lazy(() => import("./pages/bo/wallet/Wallet"));
const AgentScreen = lazy(() => import("./pages/bo/agents/Agents"));

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route 
                    path={SIGNUP_SCREEN}
                    element={
                        <Suspense fallback={".."}>
                            <SignUpScreen />
                        </Suspense>
                    } 
                />
                <Route 
                    path={DEFINE_PWD_SCREEN}
                    element={
                        <Suspense fallback={".."}>
                            <ResetPwdScreen />
                        </Suspense>
                    } 
                />
                <Route 
                    path={VERIFY_EMAIL_SCREEN}
                    element={
                        <Suspense fallback={".."}>
                            <VefifyEmailScreen />
                        </Suspense>
                    } 
                />
                <Route 
                    path={HOME_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Tableau de bord"} showBreadcrumb={false}>
                            <Suspense fallback={".."}>
                                <HomeScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={TRANSACTIONS_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Transactions"}>
                            <Suspense fallback={".."}>
                                <TransactionsScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={WALLET_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Portefeuille"}>
                            <Suspense fallback={".."}>
                                <WalletScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={ANALYSIS_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Analyse"}>
                            <Suspense fallback={".."}>
                                <AnalysisScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={ADMIN_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Administrateur"}>
                            <Suspense fallback={".."}>
                                <AdminScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={SPONSORSHIP_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Parrainage"}>
                            <Suspense fallback={".."}>
                                <SponsorScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={MERCHANTS_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Marchands"}>
                            <Suspense fallback={".."}>
                                <MerchantsScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={ACCOUNTS_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Comptes marchand"}>
                            <Suspense fallback={".."}>
                                <AccountsScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path={AGENTS_SCREEN}
                    element={
                        <PrivateRoute>
                            <Layout title={"Agents"}>
                            <Suspense fallback={".."}>
                                <AgentScreen />
                            </Suspense>
                            </Layout>
                        </PrivateRoute>
                    } 
                />
            </Routes>
        </Router>
    )
};

export default AppRouter;