import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PrimeReactProvider } from "primereact/api";

import "primeflex/primeflex.scss";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from "dayjs/plugin/duration";
import "dayjs/locale/fr";
import { AuthProvider } from './context/AuthContext';
import { GlobalStoreProvider } from './context/GlobalContext';

dayjs.extend(localizedFormat);
dayjs.extend(duration);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <GlobalStoreProvider>
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
      </GlobalStoreProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
