import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_URL, HOME_SCREEN, SIGNUP_SCREEN } from "../settings/constant";
import axiosInstanceUser from "../config/axiosConfig";

// Interface pour définir les types des fonctions d'authentification
interface AuthContextType {
  user: any;
  userProfil: any;
  activeAccount: (code: string) => Promise<number>;
  signIn: (formData: any) => Promise<number>;
  signOut: () => void;
  verifyEmail: (email: string) => Promise<number>;
  confirmEmail: (code: string, token: string) => Promise<number>;
  resetPassword: (formDate: any) => Promise<number>;
  updateUserProfil: (id: string, formData: any) => Promise<number>;
  getUserProfil: (id: string) => Promise<number>;
}


// Création du contexte d'authentification
const AuthContext = createContext<AuthContextType>({
  user: null,
  userProfil: null,
  activeAccount: async () => { return 500; },
  signIn: async () => { return 500; },
  signOut: () => {},
  verifyEmail: async () => { return 500; },
  confirmEmail: async () => { return 500; },
  resetPassword: async () => { return 500; },
  updateUserProfil: async () => { return 500; },
  getUserProfil: async () => { return 500; },
});

// Hook personnalisé pour utiliser le contexte d'authentification dans les composants
export const useAuth = (): AuthContextType => useContext(AuthContext);

// Composant fournisseur de contexte d'authentification
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [admin, setAdmin] = useState<any>(null);
  const [userProfil, setUserProfil] = useState<any> (null);

  const activeAccount = async (code: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/active-account`, { code: code });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de l'activation du compte :", error);
      setError("Une erreur est survenue lors de l'activation du compte.");
      return error.response?.status || 500;
    }
  };

  const signIn = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/login`, formData);

      if (response.status === 200) {
        const data = response.data;
          const decoded: any = jwtDecode(data.accessToken);
          const userDetail = decoded.user;
          setUser(userDetail)

          console.log(userDetail);
          localStorage.setItem("accessToken", data.accessToken);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      return error.response?.status || 500;
    }
  };

  const verifyEmail = async (email: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/verify-email`, {
        email: email,
      });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  }

  const confirmEmail = async (code: string, token: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/confirm-email`, {
        code: code,
        token: token,
      });

      if (response.status === 200) {
        localStorage.setItem("userId", response.data.id);
      }

      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  }

  const signOut = () => {
    // Logique de gestion de la déconnexion
    localStorage.removeItem("accessToken");
    window.location.href = SIGNUP_SCREEN;
  };

  const resetPassword = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/reset-password`, formData);
      
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  };

  const getUserProfil = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}get-user-profil/${id}`);
      if(response?.status === 200) {
        setUserProfil(response.data);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la récupération des informations utilisateur :", error);
      return error.response?.status || 500;
    }
  }

  const updateUserProfil = async (id: string, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}user-profil-update/${id}`, formData);
      if(response?.status === 200) {
        getUserProfil(response.data.userId);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la récupération des informations utilisateur :", error);
      return error.response?.status || 500;
    }
  }


  const context = {
    error,
    user,
    userProfil,
    activeAccount,
    signIn,
    signOut,
    verifyEmail,
    confirmEmail,
    resetPassword,
    getUserProfil,
    updateUserProfil,
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      const decoded: any = jwtDecode(token);
      const userDetail = decoded.user;
      setUser(userDetail)
    }
  }, []);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
