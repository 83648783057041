import React, { useState } from "react";
import { ReactNode } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import "./Layout.scss";
import SideBar from "../components/sideBar/SideBar";
import { Badge } from "primereact/badge";
import { useAuth } from "../context/AuthContext";

interface LayoutProps {
    children: ReactNode,
    title?: string;
    showBreadcrumb?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, title, showBreadcrumb = true }) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const { user } = useAuth();
    const items = [{ label: title },];
    const home = { icon: '',  }

    console.log(user)
    return (
        <main className="MainApp">
            <SideBar isVisible={isSidebarVisible} setIsVisible={setIsSidebarVisible} />
            <div className="MainApp-center">
                <div className="Header">
                    <button 
                        className="Header-hambuger pi pi-bars"
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)} 
                        />
                    <p className="Header-text">Hello <span>agent</span> <b>{user?._doc?.name}</b> !</p>
                    <div className="Header-right">
                        <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.35rem' }}>
                            <Badge value="2" severity={"warning"}></Badge>
                        </i>
                        {/* <Button className="Header-logout" icon="pi pi-sign-out" /> */}
                    </div>
                </div>
                <div className="MainApp-container">
                    {showBreadcrumb && <BreadCrumb className="" model={items} home={home} />}
                    <div className={`MainApp-block ${showBreadcrumb ? 'border':''} `}>{children}</div>
                </div>
            </div>
        </main>
    );
};

export default Layout;