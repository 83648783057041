import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { SIGNUP_SCREEN } from "./settings/constant";
import { useAuth } from "./context/AuthContext";

const PrivateRoute:React.FC<{ children: ReactNode }> = ({ children }) => {
    const token = localStorage.getItem("accessToken");
    console.log(token)
    if (!token) {
        return <Navigate to={SIGNUP_SCREEN} replace />
    }

    return <>{children}</>;
};

export default PrivateRoute;