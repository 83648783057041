import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import { Button } from "primereact/button";

import dashbord2 from "../../assets/icons/speedometer-outline.svg";
import dashbord from "../../assets/icons/speedometer.svg";
import transaction from "../../assets/icons/transaction.svg";
import transaction2 from "../../assets/icons/transaction-outline.svg";
import compte from "../../assets/icons/audience.svg";
import compte2 from "../../assets/icons/audience-outline.svg";
import trend from "../../assets/icons/trend.svg";
import trend2 from "../../assets/icons/trend-outline.svg";
import sponsor from "../../assets/icons/sponsorship.svg";
import sponsor2 from "../../assets/icons/sponsorship-outline.svg";
import agent from "../../assets/icons/call-center.svg";
import agent2 from "../../assets/icons/call-center-outline.svg";
import admin from "../../assets/icons/admin.svg";
import admin2 from "../../assets/icons/admin-outline.svg";
import wallet from "../../assets/icons/wallet.svg";
import wallet2 from "../../assets/icons/wallet-outline.svg";
import { FaUserCircle } from "react-icons/fa";
import { ACCOUNTS_SCREEN, ADMIN_SCREEN, AGENTS_SCREEN, ANALYSIS_SCREEN, HOME_SCREEN, SPONSORSHIP_SCREEN, TRANSACTIONS_SCREEN, WALLET_SCREEN } from "../../settings/constant";
import { useAuth } from "../../context/AuthContext";
import { Dialog } from "primereact/dialog";

const SideBar:React.FC<{ isVisible: boolean, setIsVisible: (val: boolean) => void }> = ({
    isVisible,
    setIsVisible
}) => {
    const [activeLink, setActiveLink] = useState<string | null>(null);
    const [hoveredLink, setHoveredLink] = useState<string | null>(null);
    const [visible, setVisible] = useState(false);
    const { signOut, user } = useAuth();
    const userDetail = user;

    const links = [
        {icon: dashbord2, iconActive: dashbord, label: "Tableau de bord", href: HOME_SCREEN},
        ...(userDetail?.permissions?.includes("Tout") ? 
            [
                ...(userDetail?.permissions?.includes("Tout")
                ? [
                    {icon: transaction2, iconActive: transaction, label: "Transactions", href: TRANSACTIONS_SCREEN},
                    {icon: compte2, iconActive: compte, label: "Comptes marchand", href: ACCOUNTS_SCREEN },
                    {icon: admin2, iconActive: admin, label: "Utilisateurs", href: ADMIN_SCREEN},
                    {icon: agent2, iconActive: agent, label: "Agents", href: AGENTS_SCREEN},
                    {icon: trend2, iconActive: trend, label: "Analyse", href: ANALYSIS_SCREEN},
                    {icon: wallet2, iconActive: wallet, label: "Portefeuille", href: WALLET_SCREEN},
                ] : [])
            ] : [
                ...(userDetail?.permissions?.includes("Transactions") 
                ?  [
                    {icon: transaction2, iconActive: transaction, label: "Transactions", href: TRANSACTIONS_SCREEN},
                ] : []),
            ...(userDetail?.permissions?.includes("Comptes marchand") 
                ?  [
                    {icon: compte2, iconActive: compte, label: "Comptes marchand", href: ACCOUNTS_SCREEN },
                ] : []),
            ...(userDetail?.permissions?.includes("Utilisateurs") 
                ?  [
                    {icon: admin2, iconActive: admin, label: "Utilisateurs", href: ADMIN_SCREEN},
                ] : []),
            ...(userDetail?.permissions?.includes("Agents") 
                ?  [
                    {icon: agent2, iconActive: agent, label: "Agents", href: AGENTS_SCREEN},
                ] : []),
            ...(userDetail?.permissions?.includes("Analyse") 
                ?  [
                    {icon: trend2, iconActive: trend, label: "Analyse", href: ANALYSIS_SCREEN},
                ] : []),
            ...(userDetail?.permissions?.includes("Portefeuille") 
                ?  [
                    {icon: wallet2, iconActive: wallet, label: "Portefeuille", href: WALLET_SCREEN},
                ] : []),
            ])
        
        
        // {icon: sponsor2, iconActive: sponsor, label: "Parrainage", href: SPONSORSHIP_SCREEN},
    ];

    const handleMouseEnter = (href: string) => {
        setHoveredLink(href);
    };

    const handleMouseLeave = () => {
        setHoveredLink(null);
    };

    const handleLinkClick = (href: string) => {
        setActiveLink(href);
    };

    useEffect(()=> {
        const pathname = window.location.pathname;
        handleLinkClick(pathname);
    });

    return (
        <div className={`SideBar ${isVisible ? 'SideBar-open' : ''}`}>
            <Dialog
                header={"Confirmation"}
                headerStyle={{
                    paddingLeft: "24px",
                    paddingTop: "15px"
                }}
                visible={visible} 
                style={{ width: '400px' }} 
                closable={false}
                onHide={() => setVisible(false)}
                contentStyle={{textAlign: 'center'}}>
                <div className="Forms flex flex-column gap-1 p-4">
                    <p>Voulez-vous vraiment vous déconnecter ?</p>
                    <div className="flex gap-2 mt-4 justify-content-end">
                        <Button label="Oui" severity="danger" onClick={signOut} size="small" />
                        <Button label="Non" severity="danger" outlined onClick={() => setVisible(false)} size="small" />
                    </div>
                </div>
            </Dialog>
            <Button 
                className="SideBar-close"  
                icon="pi pi-times"
                onClick={() => setIsVisible(!isVisible)}
                rounded/>
            <ul className="SideBar-ul">
                <li className="SideBar-user"><FaUserCircle /></li>
                <ul className="SideBar-ul2">
                    {links.map((list, index) => {
                        const isActive = activeLink === list.href || hoveredLink === list.href;
                        return (
                            <li
                                className="SideBar-li"
                                key={index}
                                onMouseEnter={() => handleMouseEnter(list.href)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleLinkClick(list.href)}
                            >
                                <a href={list.href} className={`SideBar-a ${isActive ? 'active':''}`}>
                                    <img className="SideBar-icon" src={isActive ? list.iconActive : list.icon} alt="" />
                                    <span className="SideBar-span">{list.label}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <Button className="SideBar-logout" icon="pi pi-sign-out" onClick={() => setVisible(true)} />
            </ul>
        </div>
    )
};

export default SideBar;